import React, { FC, PropsWithChildren } from 'react'

import { styled, mq } from '../styles'
import H1 from './base/H1'

const Title: FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => {
  return <BaseTitle className={className}>{children}</BaseTitle>
}

const BaseTitle = styled(H1)()

export default Title
