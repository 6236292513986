import { MetaFragment, FooterFragment, Maybe } from '../../graphql-types'

type QueryData = {
  prismicSplash?: Maybe<{
    data?: Maybe<MetaFragment & FooterFragment>
  }>
}

const extractGlobals = <T extends QueryData>(data?: T) => {
  const splashData = data?.prismicSplash?.data
  if (!splashData) return { meta: null, footer: null }
  const meta = {
    page_title: splashData.page_title,
    page_description: splashData.page_description,
    site_url: splashData.site_url,
    product_page_url: splashData.product_page_url,
    social_image: splashData.social_image,
    keywords: splashData.keywords,
  }
  const footer = {
    footer_contact_email: splashData.footer_contact_email,
    footer_contact_link_text: splashData.footer_contact_link_text,
    footer_social_links: splashData.footer_social_links,
    footer_links: splashData.footer_links,
  }
  return {
    meta,
    footer,
  }
}

export default extractGlobals
