import styled from '@emotion/styled'
import React, { FC, PropsWithChildren } from 'react'
import { Maybe } from '../../graphql-types'
import { fonts, mq } from '../styles'
import BaseTitle from './Title'

interface DocumentContentProps {
  title?: Maybe<string>
  content?: Maybe<string>
  containerStyle?: React.CSSProperties
}

const DocumentContent: FC<PropsWithChildren<DocumentContentProps>> = ({
  title,
  content,
  children,
  containerStyle,
}) => {
  return (
    <Container style={containerStyle}>
      <Title>{title}</Title>
      {content && <Content dangerouslySetInnerHTML={{ __html: content }} />}
      {children}
    </Container>
  )
}

const Title = styled(BaseTitle)(
  mq({
    margin: ['1.15em auto .5em', '1.6em auto .5em'],
  }),
)

const Content = styled('div')(
  mq({
    maxWidth: 800,
    margin: '0 auto',
    fontFamily: fonts.monosten.light,
    h4: {
      fontFamily: fonts.topol,
      fontSize: '2em',
      fontWeight: 'normal',
      margin: '.5em 0',
      marginTop: '1.5em',
    },
  }),
)

const Container = styled('div')({})

export default DocumentContent
