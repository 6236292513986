import React, { FC, PropsWithChildren } from 'react'

import { styled, mq, fonts } from '../../styles'

const H1: FC<PropsWithChildren<{ className?: string }>> = (props) => {
  return (
    <Container className={props.className}>
      <Text>{props.children}</Text>
    </Container>
  )
}

const Container = styled.div(
  mq({
    margin: '0 auto',
    textAlign: 'center',
    fontFamily: fonts.garamond.condensed,
    maxWidth: [1400],
    fontSize: ['7em', '4em', '5em', '4em'],
    lineHeight: [0.9, 0.9, 0.75, 0.9],
  }),
)

const Text = styled.span({
  position: 'relative',
  top: '0.04em',
})

export default H1
