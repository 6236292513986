import React, { FC, useEffect, useMemo } from 'react'
import { graphql, PageProps } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { isMobile, isIOS } from 'react-device-detect'

import Layout from '../components/Layout'
import '../components/base.css'
import {
  AppQuery,
  ImagePropsFragment,
  Maybe,
  PrismicLinkType,
} from '../../graphql-types'
import DocumentContent from '../components/DocumentContent'
import extractGlobals from '../lib/extractGlobals'
import FluidImage from '../components/base/FluidImage'
import { styled, fonts, mq } from '../styles'
import Footer from '../components/Footer'

const App: FC<PageProps<AppQuery>> = ({ data }) => {
  const { meta, footer } = extractGlobals(data)
  const { iosUrl, androidUrl } = useMemo(() => {
    const ios = data.prismicSplashApp?.data?.app?.find(
      (a) => a?.name?.text === 'ios',
    )
    const android = data.prismicSplashApp?.data?.app?.find(
      (a) => a?.name?.text === 'android',
    )
    return {
      iosUrl: ios ? ios.link?.url : '',
      androidUrl: android ? android.link?.url : '',
    }
  }, [data])

  useEffect(() => {
    document.body.style.height = 'unset'
    return () => {
      document.body.style.height = '100%'
    }
  }, [])

  useEffect(() => {
    if (isMobile) {
      if (isIOS && iosUrl) window.location.href = iosUrl
      else if (androidUrl) window.location.href = androidUrl
    }
  }, [iosUrl, androidUrl])

  return (
    <Layout tabNames={[]} meta={meta}>
      <DocumentContent
        title={data.prismicSplashApp?.data?.title?.text}
        content={null}
        containerStyle={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <BodyText>{data.prismicSplashApp?.data?.body_text?.text}</BodyText>
        <AppBlockContainer>
          {data.prismicSplashApp?.data?.app?.map((a, index) => (
            <AppBlock key={`app-${index}`} image={a?.image} link={a?.link} />
          ))}
        </AppBlockContainer>
        <BottomContainer>
          <Footer data={footer} />
        </BottomContainer>
      </DocumentContent>
    </Layout>
  )
}

const BodyText = styled.div(
  {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: fonts.monosten.light,
    margin: '0 auto',
    textAlign: 'center',
  },
  mq({
    maxWidth: [400, '80%'],
  }),
)

const AppBlockContainer = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '20px 0 100px',
    alignItems: 'center',
  },
  mq({
    flexDirection: ['row', 'column', 'row'],
    padding: ['20px 0 100px', '40px 0 100px'],
  }),
)

const BottomContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: '1px solid black',
})

const AppBlock: FC<{
  image?: Maybe<{ alt?: Maybe<string>; fluid?: Maybe<ImagePropsFragment> }>
  link?: Maybe<Pick<PrismicLinkType, 'url'>>
}> = ({ image, link }) => {
  return (
    <>
      {image && link && link.url && (
        <ImageContainer>
          <a href={link.url} target="_blank">
            <BlockImage
              image={image}
              aspectRatio={image.fluid?.aspectRatio}
              transparent={true}
              alt={image.alt}
            />
          </a>
        </ImageContainer>
      )}
    </>
  )
}

const ImageContainer = styled.div({
  display: 'flex',
  padding: '.5em',
})

const BlockImage = styled(FluidImage)<{ aspectRatio?: number }>(
  {
    minWidth: 150,
  },
  (props) =>
    mq({
      width: [
        `calc(50px * ${props.aspectRatio})`,
        200,
        `calc(50px * ${props.aspectRatio})`,
      ],
    }),
)

export default withPreview(App)

export const pageQuery = graphql`
  query App {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
    prismicSplashApp {
      data {
        title {
          text
        }
        body_text {
          text
        }
        app {
          link {
            url
          }
          name {
            text
          }
          image {
            alt
            fluid(maxWidth: 400) {
              ...ImageProps
            }
          }
        }
      }
    }
  }
`
